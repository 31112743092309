/* eslint-disable */

import { i18n } from '@/locales/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import JsonField from '@/shared/fields/json-field';
import DateTimeField from '@/shared/fields/date-time-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import IntegerField from '@/shared/fields/integer-field';
// import DecimalField from '@/shared/fields/decimal-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';

function label(name) {
  return i18n(`notification.fields.${name}`);
}
// function enumeratorLabel(name, value) {
//   return i18n(`notification.enumerators.${name}.${value}`);
// }

const fields = {
  id: new IdField('id', label('id'), { required: true }),
  title: new JsonField('title', label('title'), ['en', 'ar']),
  description: new JsonField('description', label('description'), ['en', 'ar']),
  location: new JsonField('location', label('location'), ['en', 'ar']),
  image: new JsonField('image', label('image'), ['en', 'ar']),
  imageEn: new StringField('imageEn', label('image'), { required: true }),
  imageAr: new StringField('imageAr', label('image'), { required: true }),
  eventTime: new DateTimeField('eventTime', label('eventTime')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class EventModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
