<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="event.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="event.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('event.fields.title') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.title.en"
                    :placeholder="i18n('event.fields.english')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.title.ar"
                    :placeholder="i18n('event.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('event.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.en"
                    :placeholder="i18n('event.fields.english')"
                  ></textarea>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.ar"
                    :placeholder="i18n('event.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('event.fields.location') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="location-en"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.location.en"
                    :placeholder="i18n('event.fields.english')"
                  ></textarea>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <textarea
                    id="location-ar"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.location.ar"
                    :placeholder="i18n('event.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center gap-3 relative">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('event.fields.eventTime') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div class="preview">
                    <div class="relative mx-auto">
                      <div
                        class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                      >
                        <CalendarIcon class="w-4 h-4" />
                      </div>
                      <Litepicker
                        v-if="!isRTL"
                        v-model="model.eventTime"
                        :options="{
                          lang: 'en',
                          timePicker: true,
                          autoApply: true,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true
                          }
                        }"
                        class="form-control pl-12"
                      />
                      <Litepicker
                        v-else
                        v-model="model.eventTime"
                        :options="{
                          lang: 'ar',
                          timePicker: true,
                          autoApply: true,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true
                          }
                        }"
                        class="form-control pr-12"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('event.fields.image') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && imageEnUrl">
                    <img
                      :src="imageEnUrl"
                      alt=""
                      style="width: 100%; height: 150px"
                    />
                    <!-- <img :src="/images/eventPlaceholder.png" alt="" style="width: 100%" /> -->
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.image.en = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderEn" v-else></Uploader>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <div class="relative" v-if="isEditing && imageArUrl">
                    <img
                      :src="imageArUrl"
                      alt=""
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.image.ar = undefined"
                    />
                  </div>
                  <Uploader ref="uploaderAr" v-else></Uploader>
                </div>
              </div>

              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  @click="doCancel()"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { EventModel } from '@/store/event/event-model'
import Uploader from '@/views/event/uploader.vue'

const { fields } = EventModel
const formSchema = new FormSchema([
  fields.id,
  fields.title,
  fields.description,
  fields.location,
  fields.eventTime,
  fields.image
])

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    Uploader
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'event/form/record',
      findLoading: 'event/form/findLoading',
      saveLoading: 'event/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSaveEn() {
      return this.$refs.uploaderEn?.filesToSave || []
    },
    filesToSaveAr() {
      return this.$refs.uploaderAr?.filesToSave || []
    },
    imageEnUrl() {
      return this.model && this.model.image ? this.model.image.en : null
    },
    imageArUrl() {
      return this.model && this.model.image ? this.model.image.ar : null
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.event')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.event')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }

    this.model = formSchema.initialValues(this.record || {})

    this.model.eventTime = ''
    if (this.isEditing) {
      this.model.title = this.record.title
      this.model.description = this.record.description
      this.model.eventTime = this.record.eventTime
    }
  },
  methods: {
    ...mapActions({
      doNew: 'event/form/doNew',
      doFind: 'event/form/doFind',
      doCreate: 'event/form/doCreate',
      doUpdate: 'event/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.title.en.length == 0 || this.model.title.ar.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }

      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (
        this.model.location.en.length == 0 ||
        this.model.location.ar.length == 0
      ) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (this.model.eventTime.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }

      if (this.isEditing) {
        if (!(this.imageArUrl || this.filesToSaveAr.length !== 0)) {
          this.errorMessage = 'notifications.create.popup.emptyFields'
          return false
        }
        if (!(this.imageEnUrl || this.filesToSaveEn.length !== 0)) {
          this.errorMessage = 'notifications.create.popup.emptyFields'
          return false
        }
      } else {
        if (
          this.filesToSaveEn.length === 0 ||
          this.filesToSaveAr.length === 0
        ) {
          this.errorMessage = 'notifications.create.popup.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/event')
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const { id, ...values } = formSchema.cast(this.model)
      //
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values,
          imageEn: this.imageEnUrl || this.filesToSaveEn[0],
          imageAr: this.imageArUrl || this.filesToSaveAr[0]
        })
      } else {
        await this.doCreate({
          values,
          imageEn: this.filesToSaveEn[0],
          imageAr: this.filesToSaveAr[0]
        })
      }
    }
  }
})
</script>
